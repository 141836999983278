<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="data.date_of_effective"
              label="Date of Effective"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-card
            flat
          >
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col
                md="4"
                cols="12"
              ></v-col>
              <v-row>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="data.last_name"
                    label="Last Name"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="data.first_name"
                    label="First Name"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field
                    v-model="data.middle_name"
                    label="Middle Name"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="data.gender"
                    label="Gender"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-text-field
                    v-model="data.address"
                    label="Specify Address"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  md="3"
                  cols="12"
                >
                  <v-text-field
                    v-model="data.contact_no"
                    label="Contact #"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-card-text>
      <foundation
        :key="this.key"
        :data="compensation_histories"
      ></foundation>
    </v-card>
    <v-dialog v-model="can_update" persistent max-width="80%">
      <NewEmployee
        v-on:data="on_update_info"
        :key="this.key"
        :for_update="true"
        :information_details="this.data"></NewEmployee>
      <v-btn color="error" @click="can_update = false"> Close</v-btn>
    </v-dialog>
  </div>
</template>

<script>
  import {mapActions} from "vuex";
  import NewEmployee from '@/views/other_transaction/register/NewMember'
  import snackBarDialog from "@/components/dialogs/notifications_dialog/Snackbar";
  import Foundation from "@/views/other_transaction/search/components_member/CompensationHistory";

  const initialState = () => {
    return {
      can_update: false,
      key: 0,
      branch_city: '',
      branch_province: '',
      branch_region: '',
    }
  }
  export default {
    components: {
      Foundation,
      NewEmployee,
      snackBarDialog,
    },
    props: {
      data: Object,
      compensation_histories: Array,
      cropped: String,
    },
    data() {
      return initialState()
    },
    mounted() {
      this.branch_city = this.data.branch_city != null ? this.data.branch_city.city : ''
      this.branch_province = this.data.branch_province != null ? this.data.branch_province.province : ''
      this.branch_region = this.data.branch_region != null ? this.data.branch_region.region : ''
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('employee', ['initialize_new_employee', 'is_employee_already_encoded', 'register_new_employee']),
      updating() {
        this.key++
        this.can_update = true
      },
      on_update_info() {
        this.can_update = false
        this.$emit('data', 'Update')
      }
    },
  }
</script>
