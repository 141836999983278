<template>
  <div>
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.title"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>
    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <account
          :key="keeyyyy"
          v-on:data="on_update_status_employee"
          :cropped="cropped"
          :data="member_information"
          :compensation_histories="compensation_histories"
          :is_personal="is_personal"
        ></account>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import Account from "@/views/other_transaction/search/components_member/PersonalInformation";
  import Foundation from "@/views/other_transaction/search/components_member/CompensationHistory";
  import {
    mdiAccountCashOutline,
    mdiAccountOutline,
    mdiBallotOutline,
    mdiCash,
    mdiCashRefund,
    mdiCellphone,
    mdiCharity,
    mdiClipboardClockOutline,
    mdiContentSaveMoveOutline, mdiCubeScan, mdiGift,
    mdiGoogleCirclesCommunities, mdiHandshakeOutline,
    mdiInformationOutline,
    mdiShieldAccount
  } from "@mdi/js";

  export default {
    props: {
      keeyyyy: Number,
      is_personal: Boolean,
      position: String,
      cropped: String,
      member_information: Object,
      compensation_histories: Array,
      memo_history: Array,
      change_history: Array,
    },
    components: {
      Account,
      Foundation,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiCash,
          mdiCellphone,
          mdiClipboardClockOutline,
          mdiContentSaveMoveOutline,
          mdiCashRefund,
          mdiShieldAccount,
          mdiGoogleCirclesCommunities,
          mdiBallotOutline,
          mdiCharity,
          mdiHandshakeOutline,
          mdiCubeScan,
          mdiGift,
        },
      }
    },
    data() {
      return {
        tab: '',
        tabs: [
          {title: 'PERSONAL INFORMATIONS', icon: mdiAccountOutline},
        ],
      }
    },
    methods: {
      async on_update_status_employee() {
        this.$emit('data', ' ')
      },
    }
  }
</script>

<style scoped>

</style>
