<template>
  <v-card flat
          class="pa-3 mt-2">
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-data-table dense
                        :headers="headers"
                        :items="data"
          >
            <template v-slot:item="{ item }">
              <tr
              >
                <td>
                  {{ item.date }}
                </td>
                <td>
                  {{ item.type_of_service }}
                </td>
                <td>
                  {{ formatPrice(item.amount) }}
                </td>
                <td>
                  {{ item.date_of_expiration }}
                </td>
                <td>
                  <v-chip
                    v-if="item.is_active===1"
                    small
                    color="success"
                    class="font-weight-medium"
                  >
                    ACTIVE
                  </v-chip>
                  <v-chip
                    v-else
                    small
                    color="error"
                    class="font-weight-medium"
                  >
                    EXPIRED
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mdiPlusBox, mdiThumbUp, mdiThumbDown} from '@mdi/js'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import ChangeCompensation
    from '@/views/file_interface/search/components_emloyee/components/ChangeCompensation'
  import {mapActions, mapGetters} from 'vuex'

  export default {
    props: {
      member_information: Object,
      data: Array,
    },
    components: {
      ChangeCompensation,
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiPlusBox,
          mdiThumbUp,
          mdiThumbDown,
        },
      }
    },
    data() {
      return {
        is_change_salary: false,
        status: 'DEACTIVATED',
        key: 0,
        headers: [
          {text: 'Date Effective', value: 'name', sortable: false},
          {text: 'Type of Service', value: 'name', sortable: false},
          {text: 'Rate', value: 'name', sortable: false},
          {text: 'Date of Expiration', value: 'name', sortable: false},
          {text: 'Status', value: 'name', sortable: false},
        ],
      }
    },
    computed: {
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['employee_id', 'position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('employee_compensation_history', ['approve_compensation']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      on_change(value) {
        var color = 'error'
        if (value.status === 200) {
          color = 'success'
          this.is_change_salary = false
        }
        this.change_snackbar({
          show: true,
          color: color,
          text: value.data,
        })

      },
      change_stat_compensation(value, id) {
        const data = new FormData()
        data.append('id', id)
        data.append('is_approved', value)
        data.append('employee_id_approver', this.employee_id)
        this.approve_compensation(data)
          .then(response => {
            var color = 'error'
            if (value.status === 200) {
              color = 'success'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving_data = false
          })
          .catch(error => {
            console.log(error)
          })
      },
      change_compensation_details() {
        this.key++
        this.is_change_salary = true
      },
    },
  }
</script>
